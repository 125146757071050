<template>
  <div class="project-details">
    <div class="container pt-3" v-if="!loading">
      <hr class="mt-0" />
      <div class="row">
        <div class="col-md-4">
          <h6 class="subtitle">Project</h6>
          <h5>{{ project.name }}</h5>
        </div>
        <div class="col-md-4 text-start text-md-center">
          <h6 class="subtitle">Sector</h6>
          <h5>{{ project.sector }}</h5>
        </div>
        <div class="col-md-4 text-start text-md-end">
          <h6 class="subtitle">Organization</h6>
          <h5>{{ project.organization }}</h5>
        </div>
      </div>
      <hr />
      <div class="image mb-3">
        <img :src="project.cover" alt="image" class="w-100" />
      </div>
      <div class="row">
        <div class="col-md-6" v-html="project.description"></div>
        <div class="col-md-6">
          <div class="card">
            <div class="card-body bg-white">
              <h6>About</h6>
              <ul class="list-unstyled mb-4">
                <li class="mb-2 text-muted">
                  <i class="fa fa-building me-2"></i
                  ><strong>Organization</strong>: {{ project.organization }}
                </li>
                <li class="mb-2 text-muted">
                  <i class="fa fa-flag me-2"></i><strong>Region</strong>:
                  {{ project.region }}
                </li>
                <li class="mb-2 text-muted">
                  <i class="fa fa-clock-o me-2"></i><strong>Status</strong>:
                  {{ project.status }}
                </li>
                <li class="mb-2 text-muted">
                  <i class="fa fa-calendar me-2"></i
                  ><strong>Start Date</strong>:
                  {{ project.start_date }}
                </li>
              </ul>
              <h6>Contacts</h6>
              <ul class="list-unstyled mb-4">
                <li class="mb-2 text-muted">
                  <i class="fa fa-globe me-2"></i><strong>Website</strong>:
                  {{ project.website }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container pt-3" v-if="loading">
      <loading />
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch();
  },

  data() {
    return {
      loading: true,
      project: {},
    };
  },

  methods: {
    fetch() {
      this.loading = true;

      this.$axios
        .get(`/api/v1/dashboard/my-projects/${this.$route.params.id}`)
        .then((response) => {
          this.project = response.data.project;
          this.loading = false;
        });
    },
  },
};
</script>
