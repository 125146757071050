<template>
  <div class="featured-projects">
    <div class="container">
      <h4 class="underlined">My Projects</h4>
    </div>
    <div v-if="!loading">
      <div class="featured-projects pb-5">
        <div class="container">
          <div class="row">
            <div class="col-md-9">
              <div class="row">
                <div
                  class="col-md-4"
                  v-for="(project, i) in projects"
                  :key="`featured-${i}`"
                >
                  <div class="card mb-4">
                    <div class="card-body">
                      <div class="text-center">
                        <router-link
                          :to="{
                            name: 'dashboard.my-projects.show',
                            params: { id: project.id },
                          }"
                        >
                          <span class="category">{{ project.status }}</span>
                          <img
                            :src="project.logo"
                            alt="featured"
                            class="card-img-top"
                            style="height: 150px; width: auto"
                          />
                        </router-link>
                      </div>
                      <div class="text-muted text-sm">
                        <i class="fa fa-map-marker me-2"></i
                        >{{ project.region }}
                      </div>
                      <h6 class="my-2">
                        <router-link
                          :to="{
                            name: 'dashboard.my-projects.show',
                            params: { id: project.id },
                          }"
                          >{{ project.name }}</router-link
                        >
                      </h6>
                      <p>
                        {{ project.description }}...
                      </p>
                      <router-link
                        :to="{
                          name: 'dashboard.my-projects.show',
                          params: { id: project.id },
                        }"
                        class="text-primary"
                        >Details</router-link
                      >
                    </div>
                  </div>
                </div>
                <div class="col-md-12" v-if="projects.length == 0">
                  <alert type="info">There is no data to display.</alert>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="card">
                <div class="card-body bg-white">
                  <form class="search-projects">
                    <div class="input-group mb-3">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Search..."
                      />
                      <button class="btn btn-link">
                        <i class="fa fa-search"></i>
                      </button>
                    </div>
                  </form>
                  <hr />
                  <h6 class="mb-3">Sectors</h6>
                  <ul class="sidebar-menu">
                    <li v-for="(sector, i) in sectors" :key="`sector-${i}`">
                      <router-link to=""
                        ><i class="fa fa-caret-right me-2"></i
                        >{{ sector.name }}</router-link
                      >
                    </li>
                  </ul>
                  <hr />
                  <h6 class="mb-3">Geographic Region</h6>
                  <ul class="sidebar-menu">
                    <li>
                      <router-link to=""
                        ><i class="fa fa-caret-right me-2"></i>West
                        Africa</router-link
                      >
                    </li>
                    <li>
                      <router-link to=""
                        ><i class="fa fa-caret-right me-2"></i>East And Central
                        Africa <span>5</span></router-link
                      >
                    </li>
                    <li>
                      <router-link to=""
                        ><i class="fa fa-caret-right me-2"></i>Southern
                        Africa</router-link
                      >
                    </li>
                  </ul>
                  <hr />
                  <h6>Tags</h6>
                  <p>
                    <span
                      class="badge bg-secondary me-2"
                      v-for="i in 10"
                      :key="`tag-${i}`"
                      >Tag {{ i }}</span
                    >
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <loading v-if="loading" />
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch();
  },

  data() {
    return {
      projects: [],
      sectors: [],
      loading: true,
    };
  },

  methods: {
    fetch() {
      this.loading = true;

      this.$axios.get("/api/v1/dashboard/my-projects").then((response) => {
        this.projects = response.data.projects;
        this.sectors = response.data.sectors;
        this.loading = false;
      });
    },
  },
};
</script>
